import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus, CgProductHunt } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  SiPytorch,
  SiFirebase,
  SiNextdotjs,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
     <Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc', // Border styles
    transition: 'border-color 0.3s ease-in-out', // Transition for hovering effect
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Product Operations
</Col>

<Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease-in-out',
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Product Development
</Col>

<Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease-in-out',
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Product Management
</Col>

<Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease-in-out',
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Business Management
</Col>

<Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease-in-out',
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Strategic Planning
</Col>

<Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease-in-out',
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Financial Reporting
</Col>

<Col
  className="new-class hover-border-class"
  style={{
    fontSize: '20px',
    border: '1px solid #ccc',
    transition: 'border-color 0.3s ease-in-out',
  }}
  onMouseOver={(e) => (e.currentTarget.style.borderColor = '#007bff')}
  onMouseOut={(e) => (e.currentTarget.style.borderColor = '#ccc')}
>
  Internal  Audits
</Col>


      {/* <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col> */}
      {/* <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPytorch />
      </Col> */}
    </Row>
  );
}

export default Techstack;
