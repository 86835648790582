import React from "react";
import { Container, Row } from "react-bootstrap";
// import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
// import Home2 from "../Home/Home2";
// import Type from "../Home/Type";

function Contacts() {
  return (
    <section>
      <Container fluid className="home-section" id="home">

        <div style={{marginTop:'200px'}}>

       
        </div>
        
       
      </Container>
      {/* <Home2 /> */}
    </section>
  );
}

export default Contacts;
