import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Crafting code that fuels the future, one line at a time at NSEIT.",
          "Driving innovation and excellence in software engineering at NSEIT.",
          "Empowering digital transformation with precision and passion at NSEIT."
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
